import React from 'react';
import './footer.scss'
import beian from './../images/beian.png'
function Footer (props) {
  const year = (new Date()).getFullYear()
  return (
    <div>
    <div className="footer">
      Copyright &copy; 2018-{year} 北京源堡科技有限公司 | 
      <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn">京ICP备19019047号</a>
        &nbsp;<a href="https://www.yuanbaotech.cn/sitemap.html" title="源堡科技" target="_blank" rel="noopener noreferrer"><strong>源堡科技</strong></a> <a href="https://www.yuanbaotech.cn/sitemap.xml" title="网络安全保险" target="_blank" rel="noopener noreferrer"><strong>网络安全保险</strong></a><br /><br />
      <a target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802028728"><img src={beian} alt="" /> 京公网安备 11010802028728号</a>
    </div>
  </div>
  )
}

export default Footer
