import React, {Component} from 'react';
import {observer} from "mobx-react";
import store from '../store/index'
import './home.scss'
import './research.scss'
import bg from './../images/news2.jpg'
import researchImg from './../images/re.jpg'
import researchImg2 from './../images/re2.png'
import Header from './header'
import Footer from './footer'

@observer
class Research extends Component {
  render () {
    return (
      <div className="research">
        <div className="fixMenus" style={
            store.scroll<120?{transform: 'translateY(-85px)'}:{transform: 'translateY(0)'}
          }>
          <Header fixed={true} location={'research'}></Header>
        </div>
        <div className="bg">
          <img src={bg} alt="" className="bgimg"/>
          <div className="absMenus">
            <Header location={'research'}></Header>
          </div>
          <div className="bgblock">
            <div className="words">
              <h1>研究报告</h1>
              <h2>RESEARCH</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <ul className="researchList">
            <a href="https://mp.weixin.qq.com/s/zqId8bE_A9MXRIw15-70iA" target="_blank" rel="noopener noreferrer">
            <li>
              <span><img src={researchImg} alt=""/></span>
              <div>
                <h4>《网络安全威胁信息发布管理办法》解读：构建网络安全保险健康生态</h4>
                <p>据国家网信办网站11月20日消息，为规范发布网络安全威胁信息的行为，有效应对网络安全威胁和风险，保障网络运行安全，国家互联网信息办公室会同公安部等有关部门起草了《网络安全威胁信息发布管理办法(征求意见稿)》（以下简称《办法》），近日向社会公开征求意见。</p>
              </div>
            </li>
            </a>
            <a href="https://mp.weixin.qq.com/s/2dIS8nVgabhYxoMCuqsaRQ" target="_blank" rel="noopener noreferrer">
            <li>
              <div>
                <h4>2019年全球数据泄露损失揭晓：平均每家公司损失392万美元</h4>
                <p>根据IBM和Ponemon发布的2019年全球数据泄露损失报告（Cost of a Data Breach Report 2019），2019年全球数据泄露给公司造成的平均损失达392万美元，平均每条数据泄露记录的损失为150美元。其中，数据泄露平均损失最高的国家是美国，达819万美元；数据泄露平均损失最高的行业则是医疗行业，达到645万美元。</p>
              </div>
              <span><img src={researchImg2} alt=""/></span>
            </li>
            </a>
          </ul>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Research
