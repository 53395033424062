import React, {Component} from 'react';
import {observer} from "mobx-react";
import { Icon } from 'antd';
import store from '../store/index'
import './home.scss'
import './risk.scss'
import bg from './../images/nb4.jpg'
import r1 from './../images/risk1.jpg'
import r2 from './../images/risk2.png'
// import r3 from './../images/risk3.jpg'
import i1 from './../images/risk-icon1.png'
import i2 from './../images/risk-icon2.png'
import i3 from './../images/risk-icon3.png'
// import m2 from './../images/home-m2.png'
import Header from './header'
import Footer from './footer'

// function Risk (props) {

@observer
class Risk extends Component { 
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }
  render () {
    return (
      <div className="risk">
        <div className="fixMenus" style={
            store.scroll<120?{transform: 'translateY(-85px)'}:{transform: 'translateY(0)'}
          }>
          <Header fixed={true} location={'risk'}></Header>
        </div>
        <div className="bg">
          <img src={bg} alt="" className="bgimg"/>
          <div className="absMenus">
            <Header location={'risk'}></Header>
          </div>
          <div className="bgblock">
            <div className="words">
              <h1>网络风险管理</h1>
              <h2>cyber risk management</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="contentWord">
            <h3>源堡科技：主动型风险管理框架</h3>
            <div className="img">
              <img src={r1} alt=""/>
            </div>
            <div className="infoDetail">
              <div>
                <h4>风险评估与量化</h4>
                <p>网络安全专家对目标组织或系统进行全面风险评估，并从概率和损失两个维度进行量化。</p>
              </div>
              <div>
                <h4>风险处置与转移</h4>
                <p>针对识别出来的风险，采取适当的风险处置手段，例如风险规避、风险缓解、风险保留、风险转移等。</p>
              </div>
              <div>
                <h4>风险监测与响应</h4>
                <p>针对残留风险，采用技术手段进行持续监控，一旦发现高风险，及时预警并进行相应处置。</p>
              </div>
            </div>
          </div>
        </div>

        <div className="contentbg">
          <div className="content">
            <div className="contentWord">
              <h3>主动型风险管理：“未有形而除之，防患于未然。”</h3>
              <h4>借助网络安全保险提升企业风险健康管理水平，防患于未然，实现风险未有形而除之！</h4>
              <div className="img">
                <img src={r2} alt="" className="r2"/>
              </div>
              {/* <h4 className="quote-title">主动型风险管理，我们致力于“病害没有形成就消除病因”，从而做到防患于未然</h4>
              <div className="quote">
                <img src={r3} alt="" className="risk3"/>
                <p><b>《鹖冠子》魏文侯问扁鹊：</b></p>
                <p>《鹖冠子·卷下·世贤第十六》 </p>
                <p>魏文侯曰：‘子昆弟三人其孰最善为医？’</p>
                <p>扁鹊曰：‘长兄最善，中兄次之，扁鹊最为下。’</p>
                <p>魏文侯曰：‘可得闻邪？’</p>
                <p>扁鹊曰：<strong>‘长兄于病视神，未有形而除之，故名不出于家。中兄治病，其在毫毛，故名不出于闾。若扁鹊者，镵chán血脉，投毒药，副肌肤，闲而名出闻于诸侯。’</strong></p>
                <p>魏文侯曰：‘善。使管子行医术以扁鹊之道，曰桓公几能成其霸乎！’凡此者不病病，治之无名，使之无形，至功之成，其下谓之自然。故良医化之，拙医败之，虽幸不死，创伸股维。”</p>
                
              </div> */}
            </div>
          </div>
        </div>

        <div className="content">
          <div className="contentWord">
            <h3>主动型网络安全风险管理：网络安全保险 + 风险预防服务</h3>
            <h4>目标：“不出险、少出险、出小险”</h4>
            <div className="infoDetail2">
              <div>
                <img src={i1} alt=""/>
                <h4>承保前风险场景评估</h4>
                <p>风险评估</p>
                <p>安全评测</p>
                <p>漏洞发现</p>
              </div>
              <div>
              <img src={i2} alt=""/>
                <h4>承保中风险预防管理</h4>
                <p>量化风险评估</p>
                <p>数据安全评估</p>
                <p>安全测试服务</p>
                <p>持续风险监测</p>
                <p>定期隐患排查</p>
              </div>
              <div>
                <img src={i3} alt=""/>
                <h4>出险后风险事件管理</h4>
                <p>应急响应服务</p>
                <p>数据恢复服务</p>
                <p>公关、法务服务</p>
                <p>理赔溯源信息集采</p>
              </div>
            </div>
            {/* <h4>主动型风险管理：</h4>
            <p>
            为投保客户，提供从风险评估到风险预防管理、到风险事件管理的承保前、承保中、出险后的全方位安全保障，从源头降低出险率,帮助客户减少由于网络安全事故带来的损失。
            </p> */}
          </div>
        </div>

        <div className="contentbg">
          <div className="content">
            <div className="contentWord">
              <h3>网络安全服务：精准的风险量化评估 + 主动型的损失防御服务</h3>
              {/* <h4>保险公司通过与独立第三方的量化风险评估企业合作，为投保企业提供承保前的风险的量化评估和承保后主动损失防御服务，降低企业出险概率及出险后的损失</h4> */}
              <div className="addcontent">
                <div className="c1">
                  <h4>解决可不可保、如何定价的问题</h4>
                  <h5>精准的网络安全量化评估模型</h5>
                  <ul>
                    <li>过往历史理赔案例事件</li>
                    <li>企业的网络安全成熟度</li>
                    <li>威胁概率</li>
                    <li>企业负面影响</li>
                    <li>安全措施的有效性</li>
                  </ul>
                </div>
                <div className="c2">
                  <Icon type="plus" />
                </div>
                <div className="c1">
                  <h4>解决如何尽量不出事的问题</h4>
                  <h5>主动型损失防御服务</h5>
                  <ul>
                    <li>网络安全培训</li>
                    <li>合规安全咨询</li>
                    <li>网络安全风险评估</li>
                    <li>媒体公关</li>
                    <li>法律咨询</li>
                  </ul>
                </div>
                <div className="c2"><Icon type="arrow-right" /></div>
                <div className="c3">
                  <div className="c3content">
                    <div className="text">事故概率</div>
                    <div className="icon"><Icon type="arrow-down" /></div>
                  </div>
                  <div className="c3content">
                    <div className="text">事故损失</div>
                    <div className="icon"><Icon type="arrow-down" /></div>
                  </div>
                </div>
              </div>
              <h4 className="pingce">保险公司通过与独立第三方的量化风险评估企业合作，为投保企业提供承保前的风险的量化评估和承保后主动损失防御服务，降低企业出险概率及出险后的损失</h4>
            </div>
          </div>
        </div>

        
        {/* <div className="content">
          <div className="contentWord">
            <h3>主动型风险管理价值：站在用户风险防控的角度进行安全保障</h3>
            <div className="jiazhi">
              <div className="contcolm">
                
                <div className="cbox">
                  <div className="iconbox3"><Icon type="database" /></div>
                  <div className="text">
                    <h5>厂商：利益驱动—销售思维</h5>
                    <p>1、怎么才能卖出更多产品或服务呢？</p>
                    <p>2、用户风险怎么处置呢？上全套的产品或系统？SOC?SIEM？还是SOAR? </p>
                    <p>3、用户发生安全事故了？没关系，应急响应就好，再购买一些安全设备增强防护能力？或者再购买大的APT监测平台？威胁发现平台？应急响应平台？</p>
                  </div>
                </div>

                <div className="cbox">
                  <div className="iconbox3"><Icon type="team" /></div>
                  <div className="text">
                    <h5>用户：业务驱动-保障思维</h5>
                    <p>1、我的核心业务和资产是什么？怎么保护他们不出事？</p>
                    <p>2、这些业务或资产有什么安全风险？营业中断了怎么办？那些业务会受影响？哪些数据可能泄露？哪些终端可能中勒索病毒？</p>
                    <p>3、在“有限的时间、有限的人力、有限的预算”情况下，风险如何处置？</p>
                    <p>4、如何尽量保证“不出事，少出事，出小事”？</p>
                  </div>
                </div>

                <div className="cbox">
                  <div className="iconbox3"><Icon type="star" /></div>
                  <div className="text">
                    <h5>源堡：保险驱动-保障思维</h5>
                    <p>1、用户的核心业务和资产有哪些？他们潜在损失多大？</p>
                    <p>2、这些业务或资产有什么安全风险？营业中断的概率和损失是多少？数据泄露的概率和损失是多少？终端感染勒索病毒的概率和损失是多少？</p>
                    <p>3、哪些高风险必须处置，防止出险？</p>
                    <p>4、如何尽量保证“不出险，少出险，出小险”？</p>
                  </div>
                </div>

              </div>
              <div className="imgcolm">
                <h4>目标导向</h4>
                <img src={m2} alt=""/>
                <h4>目标：“不出险，少出险，出小险”</h4>
              </div>
            </div>
          </div>
        </div> */}

        <Footer></Footer>
      </div>
    )
  }
}

export default Risk
