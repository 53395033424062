import React, {Component} from 'react';
import './home.scss'
import logo from './../images/logo-white.png'
import logoBlue from './../images/logo-blue.png'
import { Link } from "react-router-dom";
import { Menu } from 'antd';
// const { SubMenu } = Menu;

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      className: this.props.fixed ? "menus fixed" : "menus"
    }
  }
  render () {
    // const { match, location, history } = this.props
    // console.log(this.props.location)
    return (
      <div className={this.state.className}>
        <img src={
          this.props.fixed ? logoBlue : logo
        } alt="源堡科技" className="logo"/>
        <Menu mode="horizontal" className="menusDetail" defaultSelectedKeys={this.props.location} selectedKeys={this.props.location} subMenuCloseDelay={0.3}>
          <Menu.Item key="home">
          <Link to="/">首页</Link>
          </Menu.Item>
          <Menu.Item key="insurance">
            <Link to="/insurance">网络安全保险</Link>
          </Menu.Item>
          {/* <SubMenu
            key="insurance"
            title={
              <span className="submenu-title-wrapper">
                网络安全保险
              </span>
            }
          >
            <Menu.Item key="insurance:1"><Link to="/insurance">网络安全综合保险</Link></Menu.Item>
            <Menu.Item key="insurance:2"><Link to="/experts">服务和专家介绍</Link></Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            key="risk"
            title={
              <span className="submenu-title-wrapper">
                网络风险管理
              </span>
            }
          >
            <Menu.Item key="risk:1">Option 1</Menu.Item>
            <Menu.Item key="risk:2">Option 2</Menu.Item>
            <Menu.Item key="risk:3">Option 3</Menu.Item>
            <Menu.Item key="risk:4">Option 4</Menu.Item>
          </SubMenu> */}
          <Menu.Item key="risk">
            <Link to="/risk">网络风险管理</Link>
          </Menu.Item>
          {/* <Menu.Item key="research">
            <Link to="/research">研究报告</Link>
          </Menu.Item> */}
          <Menu.Item key="news">
            <Link to="/news">新闻动态</Link>
          </Menu.Item>
          {/* <SubMenu
            key="about"
            title={
              <span className="submenu-title-wrapper">
                关于源堡
              </span>
            }
          >
            <Menu.Item key="about:us"><Link to="/about">关于我们</Link></Menu.Item>
            <Menu.Item key="about:job"><Link to="/job">招贤纳士</Link></Menu.Item>
          </SubMenu> */}
          <Menu.Item key="about">
            <Link to="/about">关于我们</Link>
          </Menu.Item>
        </Menu>
        {/* <div className="reg">
          注册 / 登录 
        </div> */}
      </div>
    )
  }
}

export default Header
