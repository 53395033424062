import {observable, computed} from 'mobx'

class todo {
	@observable todos = [];
	@observable pendingRequests = 0;
	@observable scroll = 0

  // constructor() {
  //     autorun(() => console.log(this.report));
  // }

	@computed get completedTodosCount() {
    	return this.todos.filter(
			todo => todo.completed === true
		).length;
    }

	@computed get report() {
		if (this.todos.length === 0)
			return "<none>";
		return `Next todo: "${this.todos[0].task}". ` +
			`Progress: ${this.completedTodosCount}/${this.todos.length}`;
	}

	addTodo(task) {
		this.todos.push({
			task: task,
			completed: false,
			assignee: null
		});
	}

	updateScroll (y) {
		// console.log(y)
		this.scroll = y
	}
}

export default new todo()
