import React, { Component, Fragment } from 'react'
import { HashRouter, Route } from 'react-router-dom'
import store from '../store/index'
import Home from '../views/home.jsx'
import Insurance from '../views/insurance.jsx'
import Risk from '../views/risk.jsx'
import Research from '../views/research.jsx'
import News from '../views/news.jsx'
import About from '../views/about.jsx'
import Job from '../views/job.jsx'
import Experts from '../views/experts.jsx'

// import One from '../page/one';
class Router extends Component {
  // constructor (props) {
  //   console.log('router')
  //   super(props)
  // }
  componentDidMount() {
    // console.log('router mounted')
    const throttle = (func, delay) => {
      let prev = Date.now()
      return function () {
        const context = this
        const args = arguments
        const now = Date.now()
        if (now - prev >= delay) {
          func.apply(context, args)
          prev = Date.now()
        }
      }
    }
    const doScroll = (event) => {
      store.updateScroll(window.scrollY)
    }
    window.addEventListener('scroll', throttle(doScroll, 10))
  }
  render() {
    // console.log(this.props)
    return (
      <HashRouter>
        <Fragment>
          <Route exact path={`/`} component={Home} />
          <Route path={`/insurance`} component={Insurance} props={this.props} />
          <Route path={`/experts`} component={Experts} />
          <Route path={`/risk`} component={Risk} />
          <Route path={`/research`} component={Research} />
          <Route path={`/News`} component={News} />
          <Route path={`/about`} component={About} />
          <Route path={`/job`} component={Job} />
        </Fragment>
      </HashRouter>
    )
  }
}
export default Router
