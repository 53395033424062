import React from 'react';
import {observer} from "mobx-react";
import store from '../store/index'
import './home.scss'
import './about.scss'
import bg from './../images/about.jpg'
import Header from './header'
import Footer from './footer'
function Job (props) {
  return (
    <div className="about">
      <div className="fixMenus" style={
          store.scroll<120?{transform: 'translateY(-85px)'}:{transform: 'translateY(0)'}
        }>
        <Header fixed={true} location={'about:job'}></Header>
      </div>
      <div className="bg">
        <img src={bg} alt="" className="bgimg"/>
        <div className="absMenus">
          <Header location={'about:job'}></Header>
        </div>
        <div className="bgblock">
          <div className="words">
            <h1>招贤纳士</h1>
            <h2>JOIN US</h2>
          </div>
        </div>
      </div>
      <div style={{height:'300px'}}>aaa{store.scroll}bbb</div>
      <Footer></Footer>
    </div>
  )
}

export default observer(() => Job())
