import React, { Component } from 'react'
import { observer } from 'mobx-react'
import store from '../store/index'
import './home.scss'
import './insurance.scss'
import bg from './../images/bx2.jpg'
// import address from './../images/digital-asset.png'
import table from './../images/table.png'
import Header from './header'
import Footer from './footer'
import { Icon } from 'antd'
// const Insurance = (props) => {
@observer
class Insurance extends Component {
  render() {
    return (
      <div className="ins">
        <div
          className="fixMenus"
          style={
            store.scroll < 120
              ? { transform: 'translateY(-85px)' }
              : { transform: 'translateY(0)' }
          }
        >
          <Header fixed={true} location={'insurance:1'}></Header>
        </div>
        <div className="bg">
          <img src={bg} alt="" className="bgimg" />
          <div className="absMenus">
            <Header location={'insurance:1'}></Header>
          </div>
          <div className="bgblock">
            <div className="words">
              <h1>网络安全综合保险</h1>
              {/* <h2>JOIN US</h2> */}
            </div>
          </div>
          {/* <div style={{height:'1300px'}}>aaa{store.scroll}bbb</div> */}
        </div>
        <div className="content">
          <div className="contentWord">
            <p>
              随着互联网技术应用的广泛，世界正迎来一轮新的变革。企业不管从内部运营还是外部商务发展，都越来越依赖于线上的技术支持。因计算机系统、数据等虚拟网络受到攻击给企业带来的经济损失，已远高于设备、厂房、文档等实体物质给企业带来的财产损失。与之相对应的却是传统财产、责任保险产品对这一新兴网络安全风险的沉默甚至除外。新型的网络安全综合保险应运而生，保障因安全事件对企业计算机系统造成入侵或破坏，给企业带来的资产损失、财务的损失、声誉损失和法律合规责任。
            </p>
            <p>
              我们提供主动型、全方位的综合网络安全风险解决方案，从精准的网络安全风险损失预测评估到网络安全风险预防管理，再到网络安全风险损失赔偿的全周期安全保障，从源头降低出险率,帮助客户减少由于网络安全事故带来的损失。
            </p>

            <h3>保险责任介绍</h3>

            <div className="infoDetail">
              <div>
                <h4>保险赔偿</h4>
                <p>
                  <span>营业中断：</span>
                  <b>
                    发生网络安全事故导致企业营业中断，将为中断期间按损失利润提供补偿
                  </b>
                </p>
                <p>
                  <span>数据损坏：</span>
                  <b>补偿由于数据安全事故导致的数据损害需要的数据修复费用</b>
                </p>
                <p>
                  <span>网络勒索：</span>
                  <b>遭受网络安全威胁或攻击后的网络勒索赎金</b>
                </p>
                <p>
                  <span>合规法律咨询：</span>
                  <b>
                    在发生网络安全事故后，聘请法律顾问咨询网络安全法/个人信息保护法等合规问题
                  </b>
                </p>
                <p>
                  <span>名誉损失：</span>
                  <b>为消除网络安全事件影响而产生的咨询顾问费、宣传活动费</b>
                </p>
              </div>
              <div>
                <h4>应急管理</h4>
                <p>
                  <span>网络勒索危机顾问：</span>
                  <b>
                    当企业遭受网络勒索时，为企业制定应对方案并提出专业化谈判建议
                  </b>
                </p>
                <p>
                  <span>网络安全专家咨询：</span>
                  <b>
                    发生网络安全事故后，提供24小时专家电话咨询，并可在第一时间抵达事故现场
                  </b>
                </p>
                <p>
                  <span>合规整改咨询：</span>
                  <b>
                    安全专家结合网络安全法和个人信息保护法，知道企业如何进行网络整改以符合法律要求
                  </b>
                </p>
                <p>
                  <span>漏洞修复服务：</span>
                  <b>
                    网络安全专家团队将协助企业监测系统漏洞，并对漏洞进行修复
                  </b>
                </p>
                <p>
                  <span>事故溯源&amp;负面影响评估：</span>
                  <b>
                    网络安全事故发生后，需要及时判断故障究竟是由网络安全事件引起、还是断电、服务器过热烧坏、火灾等其他原因引起，才能尽快找到解决问题的方案并为保险赔偿提供依据
                  </b>
                </p>
              </div>
            </div>

            <h3>产品优势，提供的服务</h3>

            <div className="service">
              <h4>
                <Icon type="star" /> 全面的风险损失覆盖范围
              </h4>

              <table className="scope" border="0">
                <tbody>
                  <tr>
                    <td colSpan="2" width="40%">
                      保险公司
                    </td>
                    <td width="20%" className="blue">
                      国寿
                    </td>
                    <td width="10%">X安</td>
                    <td width="10%">XX农业保险</td>
                    <td width="10%">X安</td>
                    <td width="10%">X保</td>
                  </tr>
                  <tr>
                    <td colSpan="2">适用客户</td>
                    <td className="blue">中大型</td>
                    <td>中小</td>
                    <td>中小</td>
                    <td>中小型</td>
                    <td>中小</td>
                  </tr>
                  <tr>
                    <td colSpan="2">合作方</td>
                    <td className="blue">源堡科技</td>
                    <td>公安三所</td>
                    <td>公安三所</td>
                    <td>安恒信息</td>
                    <td>奇安信</td>
                  </tr>
                  <tr>
                    <td colSpan="2">保额上限</td>
                    <td className="blue">最高一亿元,可定制</td>
                    <td>60万</td>
                    <td>15万</td>
                    <td>300万</td>
                    <td>定制</td>
                  </tr>
                  <tr>
                    <td rowSpan="10">承保内容</td>
                    <td>营业中断</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>
                  <tr>
                    <td>网络勒索</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>

                  <tr>
                    <td>数据损失</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>
                  <tr>
                    <td>名誉损失</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>合规法律咨询</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>网络安全专家咨询服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>
                  <tr>
                    <td>网络勒索危机顾问服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>漏洞修复服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>合规整改咨询服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>事故溯源 &amp; 负面影响评估服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td rowSpan="10">网络安全风险预防管理服务</td>
                    <td>无感知风险评估</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>网络安全成熟度评估</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>网络安全量化场景评估</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>定期风险隐患排查</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Web 网页防篡改监测</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>
                  <tr>
                    <td>Web 网页木马与暗链监测</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Web 安全测试与加固</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>渗透测试服务</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>网络安全人才培训</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>高级威胁监测</td>
                    <td className="blue">
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                    <td></td>
                    <td>
                      <Icon type="check" />
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4>
                <Icon type="star" /> 充足的风险保额支持
              </h4>
              <h5>
                我们可以为企业提供最高1亿人民币的风险保额，充分满足所有规模企业的风险保障
              </h5>

              <h4>
                <Icon type="star" /> 定制的行业保险方案
              </h4>
              <h5>我们针对不同行业设计不同的定制方案，更加切合行业特色需求</h5>
              <ul className="plan">
                <li>
                  <div>
                    <Icon type="wifi" />
                    通信
                  </div>
                  <p>
                    通信系统记录着每一个客户的姓名电话、通话记录和地理位置等重要数据信息。但由于通信行业自身的开放性和共享性，使得其更容易受到计算机网络的攻击
                  </p>
                </li>
                <li>
                  <div>
                    <Icon type="dollar" />
                    银行/金融
                  </div>
                  <p>
                    由于系统内拥有大量客户信息，金融机构长期以来一直是黑客的重点攻击对象。恶意软件、非法使用和访问以及由第三方提供的APP线上业务，都给银行和其他金融机构带来了独有的风险。每天平均有大约120万个新的恶意软件或变体。
                  </p>
                </li>
                <li>
                  <div>
                    <Icon type="fire" />
                    能源
                  </div>
                  <p>
                    近年来，网络安全对能源企业的重要性日益受到关注。工业控制系统的易受攻击性和使用信息技术来控制操作运行的趋势，使得能源行业风险暴露程度显著提高，对企业的业务中断、财产损失和员工伤亡都会产生影响。
                  </p>
                </li>
                <li>
                  <div>
                    <Icon type="read" />
                    教育
                  </div>
                  <p>
                    教育机构由于其开放的环境、大量的学生和教师信息以及提供的广泛服务，特别容易受到网络攻击。此外，学校用来应对网络安全的资源和预算通常十分有限，因此很难跟上迅速变化的网络威胁环境。
                  </p>
                </li>
                <li>
                  <div>
                    <Icon type="shopping" />
                    零售
                  </div>
                  <p>
                    零售商手中有大量的客户信息，包括信用卡和借记卡号码。客户通常会使用同样的密码和登陆信息登陆很多账号，这使得欺诈风险增高。
                  </p>
                </li>
                <li>
                  <div>
                    <Icon type="rocket" />
                    制造业
                  </div>
                  <p>
                    制造和生产设施需要集成化且稳定的操作系统，以确保其生产的及时有效。黑客可以通过控制系统到达仪器，这意味着行业最终用户、标准组织、供应商和政府机构需要联合起来应对这一威胁。
                  </p>
                </li>
              </ul>

              <h4>
                <Icon type="star" /> 专业的风险管理服务
              </h4>
              <h5>
                我们的专家团队将为客户提供主动型、全方位的风险管理解决方案，让您在拥有保险保障的同时，也能获得专业的网络安全顾问服务
              </h5>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}

export default Insurance
