import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import store from '../store/index'
import './home.scss'
import './about.scss'
import bg from './../images/nb3.jpg'
import wechat from './../images/wechat.png'
import Header from './header'
import Footer from './footer'
import { Icon, Input, Button } from 'antd'
let forms = {}
const { TextArea } = Input
const shenqing = () => {
  console.log('shenqing')
  if (
    !forms.company ||
    forms.company.length < 5 ||
    !forms.domain ||
    !forms.name ||
    !forms.phone ||
    forms.phone.length < 8 ||
    !forms.mail ||
    !/.+@.+\..+/.test(forms.mail)
  ) {
    window.alert('请完整填写表单再申请')
  } else {
    // window.alert('您的申请已收到')
    let body = `公司：${forms.company}\n域名：${forms.domain}\n联系人：${forms.name}\n手机：${forms.phone}\n邮箱：${forms.mail}\n备注：${forms.remark}\n`
    window.location.href =
      'mailto:service@yuanbaotech.cn?subject=试用申请&body=' + body
  }
}
const handleChange = (e, n) => {
  // console.log('handleChange')
  // console.log(e.target.value)
  forms[n] = e.target.value
  // console.log(forms)
}
function About(props) {
  // use effect 完成react 生命周期事件
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div className="about">
      <div
        className="fixMenus"
        style={
          store.scroll < 120
            ? { transform: 'translateY(-85px)' }
            : { transform: 'translateY(0)' }
        }
      >
        <Header fixed={true} location={'about'}></Header>
      </div>
      <div className="bg">
        <img src={bg} alt="" className="bgimg" />
        <div className="absMenus">
          <Header location={'about'}></Header>
        </div>
        <div className="bgblock">
          <div className="words">
            <h1>关于源堡</h1>
            <h2>ABOUT US</h2>
          </div>
        </div>
      </div>
      <div className="content aboutFlex">
        <div className="aboutContent">
          <h3>关于源堡</h3>
          <p>
            源堡科技是一家专注于网络安全量化风险管理的科技企业。公司基于自主研发的网络安全风险自动化评估与管理平台，为企业提供风险量化评估分析、风险持续性监测和风险预警管理等服务。
          </p>

          <p>
            源堡科技作为网络安全量化风险管理领域的开创者和引领者，创新性的将网络安全风险量化技术与保险保障范围相结合，为保险和再保险公司提供网络安全保险、数据安全保险等相关产品的模型开发和量化风控系统，帮助保险机构解决核保、定价等核心业务难点，并为投保企业提供“保险+风险管理+服务”的主动型风险管理解决方案。
          </p>

          <p>
            源堡科技由院士领衔，汇聚了网络安全和金融领域顶尖专家。公司总部位于北京，目前已在南京、上海、深圳落地三家子公司。
          </p>

          <p>
            服务领域覆盖政府、金融、能源、电信、医疗、汽车、教育、互联网等多个重点行业。客户包括人保、太保、平安、国寿、中再、瑞再、国家电网、中信集团、中核凯利、汽车之家等国内外知名企业。
          </p>

          <p>
            自成立以来，公司已完成三轮融资，投资方包括中信创投、高瓴创投、中交鼎信、中关村发展前沿基金、启迪之星等知名机构，累计融资数亿元。
          </p>
        </div>
      </div>

      <div className="content aboutFlex pb50">
        <div className="address">
          <h3>联系方式</h3>
          <div className="">
            <div className="info">
              {/* <h4>北京源堡科技有限公司</h4> */}
              <h5>
                <Icon type="pushpin" />{' '}
                北京市丰台区金泽西路4号院丽泽平安金融中心B座26层
              </h5>
              <h5>
                <Icon type="mail" /> service@yuanbaotech.cn
              </h5>
              <h5>
                <Icon type="phone" /> 010-82151831
              </h5>
              <h5>
                <Icon type="global" /> https://www.yuanbaotech.cn
              </h5>
              <h5>
                <img src={wechat} alt="" />
              </h5>
            </div>
          </div>
        </div>
        <div className="reg">
          <h3>申请试用</h3>
          <div className="forms">
            <Input
              placeholder="公司名称"
              onChange={(e) => {
                handleChange(e, 'company')
              }}
            />
            <Input
              placeholder="请填写您企业的一级域名"
              onChange={(e) => {
                handleChange(e, 'domain')
              }}
            />
            <Input
              placeholder="联系人"
              onChange={(e) => {
                handleChange(e, 'name')
              }}
            />
            <Input
              placeholder="手机"
              onChange={(e) => {
                handleChange(e, 'phone')
              }}
            />
            <Input
              placeholder="公司邮箱"
              onChange={(e) => {
                handleChange(e, 'mail')
              }}
            />
            <TextArea
              rows={4}
              placeholder="备注"
              onChange={(e) => {
                handleChange(e, 'remark')
              }}
            />
            <Button type="primary" onClick={shenqing}>
              申请试用
            </Button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default observer(() => About())
