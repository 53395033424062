import React, {Component} from 'react';
import {observer} from "mobx-react";
import store from '../store/index'
import './home.scss'
import './news.scss'
import bg from './../images/nb5.jpg'
import Header from './header'
import Footer from './footer'
import NewsList from './news.json'
// function News (props) {
@observer
class News extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }
  render () {
    return (
      <div className="news">
        <div className="fixMenus" style={
            store.scroll<120?{transform: 'translateY(-85px)'}:{transform: 'translateY(0)'}
          }>
          <Header fixed={true} location={'news'}></Header>
        </div>
        <div className="bg">
          <img src={bg} alt="" className="bgimg"/>
          <div className="absMenus">
            <Header location={'news'}></Header>
          </div>
          <div className="bgblock">
            <div className="words">
              <h1>新闻动态</h1>
              <h2>NEWS</h2>
            </div>
          </div>
        </div>
        <div className="content">
          <ul className="newsList">
          {/* 新闻列表 */}
          {NewsList.map((news, i) => (
            <li key={i}>
              <a href={news.url} target="_blank" rel="noopener noreferrer">
                <div><img src={news.ImgUrl} alt=""/></div>
                <h4>{news.title}</h4>
                <p>{news.info}</p>
              </a>
            </li>
          ))}
          </ul>
        </div>
        <Footer></Footer>
      </div>
    )
  }
}
export default News
